import { render, staticRenderFns } from "./NiroDisclosure.vue?vue&type=template&id=6fda0c81&"
import script from "./NiroDisclosure.vue?vue&type=script&lang=js&"
export * from "./NiroDisclosure.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports